import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableDef from "components/TableDef";
import { sellerFields } from "pages/SellerProfile";
import stringcase from "stringcase";
import Image from "components/Image";
import RealtorSelect from "./Common/RealorSelect";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
// import BuyerSellerNotesModal from './Buyer/BuyerSellerNotesModal';
// import RequestReview from './Review/RequestReview';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
import { saveActivity } from "../Services/Activity"
import moment from "moment";
import firebase from "firebase/app";
// import ConfirmationModal from "./Common/Modals/ConfirmationModal";
import ChangeStatus from "./Seller/ChangeStatus";
// import CreateBlogPost from "./Blog/CreateBlogPost";
// import BlogPosts from "./Blog/BlogPosts";
// import House from "../assets/icons/gallery/Pict.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const { titlecase } = stringcase;

class SellerListItem extends Component {
  constructor(props) {
    super(props);
    const {
      sqft,
      sqft_source,
      sqft_source_documentation_type
    } = this.props.data;
    this.state = {
      error: "",
      modalVisible: false,
      reason: "",
      otherReason: "",
      contractDate: moment(),
      contractDateFocused: false,
      soldDate: moment(),
      soldDateFocused: false,
      agentName: "",
      agentPhone: "",
      agentEmail: "",
      agentLicence: "",
      sqft: sqft || "",
      sqft_source: sqft_source || "",
      sqft_source_documentation_type: sqft_source_documentation_type || "",
      price: "",
      financeType: "",
      sellerContribution: "",
      noAgent: false,
      statusColors: {
        'Active': 'success',
        'Active Kick Out': 'primary',
        'Active Option Contract': 'primary',
        'Active Contingent': 'primary',
        'Pending': 'warning',
        'Sold': 'danger',
        'Cancelled': 'dark',
        'Temp Off Market': 'dark',
        'Expired': 'dark',
        'Leased': 'dark',
        'Withdraw': 'dark'
      },
      seller: props.seller,
      sellerId: props.seller.id
    };
  }

  toggleCheckbox = name => {
    const { onStateChange } = this.props;
    this.setState(
      {
        [name]: !this.state[name]
      },
      () => {
        if (onStateChange) {
          onStateChange(this.state);
        }
      }
    );
  };

  onChange = async ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value
    });
  };

  onAgentSelect = data => {
    const {
      realtor_license,
      realtor_email,
      realtor_name,
      realtor_phone
    } = data.value;

    this.setState({
      agentLicence: realtor_license || "",
      agentEmail: realtor_email || "",
      agentName: realtor_name || "",
      agentPhone: realtor_phone || ""
    });
  };

  remove = () => {
    const { seller, remove } = this.props;
    let {
      reason,
      otherReason,
      agentEmail,
      agentLicence,
      agentName,
      agentPhone,
      contractDate,
      soldDate,
      sqft,
      sqft_source,
      sqft_source_documentation_type,
      price,
      financeType,
      sellerContribution,
      noAgent,
      sellerId
    } = this.state;

    if (reason === "") {
      return alert("Please select reason to remove");
    }

    if ((!noAgent) && agentLicence === "" && reason === "Property has Sold") {
      return alert("Please Select Agent");
    }

    const data = {
      reason,
      details: otherReason,
      agentEmail,
      agentLicence,
      agentName,
      agentPhone,
      contractDate: contractDate.format('DD/MM/YYYY'),
      soldDate: soldDate.format('DD/MM/YYYY'),
      sqft,
      sqft_source,
      sqft_source_documentation_type,
      price,
      financeType,
      sellerContribution,
      noAgent
    };
    remove(sellerId, data);
    this.setState({ modalVisible: false });
    const { user } = this.props;
    saveActivity({
      text: `A file has been uploaded.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid
    })
  };

  toggle = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible
    }));
  };

  newStatus = (status) => {
    const { seller, sellerId } = this.state
    const db = firebase.firestore();
    db.collection("sellers").doc(sellerId).set({
      MlsStatus: status
    }, {
      merge: true,
    }).then(() => {
      toast.success('Status updated successfully.')
    }).catch(error => {
      toast.error(JSON.stringify(error));
    });
    this.setState({
      seller: {
        ...seller,
        MlsStatus: status
      }
    })
  }

  address(seller) {
    const { street_number, street_name, city, state, zip_code, location } = seller;

    const getLocationWithZip = () => {
      if (!location?.name) return null;
      const zipExistsInName = location.name.includes(zip_code);
      return zipExistsInName ? location.name : `${location.name}, ${zip_code}`;
    };

    if (!seller.ListingKey) {
      return getLocationWithZip() || `${street_number} ${street_name}, ${city}, ${state}, ${zip_code}`;
    }
    if (seller.hideAddress) {
      return `${city}, ${state}, ${zip_code || ""}`;
    }
    return `${street_number} ${street_name}, ${city}, ${state}, ${
      zip_code || ""
    }`;
  }

  render() {
    const { user, showDetails, newStatus } = this.props;
    const { statusColors, seller, sellerId } = this.state;
    const data = seller
    const { is_flip_property, is_buy_and_whole_property } = data || {};
    let photo = null;
    const featuredImage = seller.featured_image;
    if (featuredImage && featuredImage.url) {
      photo = (
        <Image src={featuredImage.url} alt="listing" className="img-fluid mobile-responsive" />
      );
    } else {
      photo = (
        <img
          src="/images/house-placeholder.jpg"
          alt="listing"
          className="img-fluid"
        />
      );
    }
    return (
      <div key={sellerId} className="seller-list-item-main">
        <div className="row">
          <div className="col-md-3" style={{ marginBottom: 10 }}>
            {photo}
          </div>
          <div
            className="col-md-9 col-12 title-container p-0"
            style={{ marginBottom: 10 }}
          >
            <h2 className="sellers-name">{data.seller_name || "(Seller name)"}</h2>
            {/* {data.draft ? (
              <p>
                <span className="badge badge-info">Draft</span>
              </p>
            ) : (
              <p>
                <span className={`badge badge-${(statusColors[data.MlsStatus || 'Active' ]) || 'dark'}`}>{data.MlsStatus || 'Active'}</span>
                { (is_flip_property === "Yes") && <span className="badge badge-flip">
                  Fix & Flip
                </span>  }
                { (is_buy_and_whole_property === "Yes") && <span className="badge badge-flip">
                  Buy & Hold
                </span>  }
              </p>

            )} */}

            <h5 className="d-none d-xl-block d-lg-block d-md-block address">
              <i className="flaticon-pin pin-icon" />
              &nbsp;
              {data.hideAddress
                ? data.address || "No address specified or address hidden"
                : this.address(data) || "No address specified or address hidden"}
            </h5>
            <p className="draft-p">
              <span className={(data.MlsStatus === "Sold" || data.MlsStatus === "Closed") ? "badge review-badge" : data.MlsStatus === "Pending" ? "badge badge-review" : data.MlsStatus === "Expired" ? "badge not-accepted-badge" : data.MlsStatus === "Temporary Off Market" ? "badge badge-info draft-badge" : data.MlsStatus === "Cancelled" ? "badge not-accepted-badge" : data.MlsStatus === "Withdrawn" ? "badge badge-black status-badge" : "badge badge-success status-badge"} > {data.MlsStatus === "Sold" || data.status === "Sold" ? "Closed" : data.MlsStatus || data.status}</span>
            </p>
            {/* {data.likedByBuyers && data.likedByBuyers.length ? (z
              <>
                <small>
                  <img
                    alt=""
                    style={{ height: 15 }}
                    src={"/images/red_star1.png"}
                  />
                  &nbsp;Liked by {data.likedByBuyers.length} buyers
                </small>
                <br />
                <br />
              </>
            ) : null} */}

            <TableDef items={this.profileDetails(data)} />
          </div>
          <div
            className="col-md-9 col-sm-12 offset-md-3 action"
            style={{ marginBottom: 10 }}
          >
            {user ? <>
              {/* { showDetails && <Link to={`/matches?sellerId=${sellerId}`}>
                <i className="fa fa-list" />
                View matches
              </Link> 
              } */}
              &nbsp;&nbsp;
              <Link to={`/sellers/${sellerId}`} target="_blank" className="details-txt">
                <i className="fa fa-eye custom-fa-icon" />
                View on site
              </Link>
              &nbsp;&nbsp;
              {showDetails && <Link to={`/profiles/sellers/${sellerId}`} className="details-txt">
                <i className="fa fa-pencil custom-fa-icon" />
                Edit
              </Link>}
              &nbsp;&nbsp;
              {showDetails && <Link to={`/profiles/sellers/${sellerId}`} className="details-txt">
                <i className="fa fa-check custom-fa-icon" />
                Show Profile
              </Link>}
              &nbsp;&nbsp;
              {showDetails && <a className="details-txt" onClick={this.toggle}>
                <i className="fa fa-times custom-fa-icon" />
                Remove
              </a>}
              {showDetails && <Fragment>
                {/* <BuyerSellerNotesModal {...this.props} resourceId={sellerId} /> */}
                {/* <RequestReview resourceId={sellerId} name={data.seller_name} type="Seller" color='link' style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}>
                <i className="fa fa-star" /> Request Review
              </RequestReview>
              <CreateBlogPost {...this.props} resourceId={sellerId} name={data.seller_name} color='link' style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}>
                <i className="fa fa-star" /> Create Message Board
              </CreateBlogPost>
              <BlogPosts {...this.props} resourceId={sellerId} name={data.seller_name} color='link' style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}>
                <i className="fa fa-star" /> Message Boards
              </BlogPosts> */}
                <ChangeStatus id={sellerId} status={data.status} newStatus={this.newStatus} color='link' className="status-change-btn">
                  <i className="fa fa-star change-status-icon" /> Change Status
                </ChangeStatus>
                {this.renderRemoveSellerModal(data)}
              </Fragment>
              }
            </> : <>
              {showDetails && <Link to={`/matches?sellerId=${sellerId}`}>
                <i className="fa fa-list" />
                View matches
              </Link>
              }
              &nbsp;&nbsp;
              <Link to={`/sellers/${sellerId}`} target="_blank">
                <i className="fa fa-eye" />
                View on site
              </Link>
            </>}
          </div>
          <hr></hr>
        </div>
      </div>
    );
  }

  renderSoldFields = () => {
    const {
      reason,
      agentEmail,
      agentLicence,
      agentName,
      agentPhone,
      financeType,
      sellerContribution,
      price,
      contractDate,
      contractDateFocused,
      soldDate,
      soldDateFocused,
      sqft,
      sqft_source,
      sqft_source_documentation_type,
      noAgent
    } = this.state;

    if (reason === "Property has Sold") {
      return (
        <React.Fragment>
          <FormGroup>
            <Row>
              <Col xs="12" sm="4">
                <Label for="contractDate">Contract Date</Label><br />
                <SingleDatePicker
                  date={contractDate}
                  focused={contractDateFocused}
                  onDateChange={contractDate => this.setState({ contractDate })}
                  onFocusChange={({ focused }) => this.setState({ contractDateFocused: focused })}
                  id="contractDate"
                  numberOfMonths={1}
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={() => false}
                />
              </Col>

              <Col xs="12" sm="4">
                <Label for="soldDate">Sold Date</Label><br />
                <SingleDatePicker
                  date={soldDate}
                  focused={soldDateFocused}
                  onDateChange={soldDate => this.setState({ soldDate })}
                  onFocusChange={({ focused }) => this.setState({ soldDateFocused: focused })}
                  id="soldDate"
                  numberOfMonths={1}
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={() => false}
                />
              </Col>

              <Col xs="12" sm="4">
                <Label for="sqft">Sq ft</Label>
                <Input
                  type="number"
                  name="sqft"
                  id="sqft"
                  value={sqft}
                  onChange={this.onChange}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>


              <Col xs="12" sm="6">
                <Label for="sqft_source">Sq ft Source</Label>
                <Input
                  type="select"
                  name="sqft_source"
                  id="sqft_source"
                  value={sqft_source}
                  onChange={this.onChange}
                >
                  <option>Select Sq ft Source</option>
                  <option>Tax</option>
                  <option>Building Plan</option>
                  <option>Appraisal</option>
                  <option>Other Documentation</option>
                </Input>
              </Col>

              {(() => {
                if (sqft_source === "Other Documentation") {
                  return (
                    <Col xs="12" sm="6">
                      <Label for="sqft_source_documentation_type">
                        Sq ft Documentation Type
                      </Label>
                      <Input
                        type="text"
                        name="sqft_source_documentation_type"
                        id="sqft_source_documentation_type"
                        value={sqft_source_documentation_type}
                        onChange={this.onChange}
                      />
                    </Col>
                  );
                }
              })()}
            </Row>
          </FormGroup>

          <FormGroup check style={{ marginBottom: '10px' }}>
            <Label check>
              <Input
                type="checkbox"
                checked={noAgent}
                onChange={() => this.toggleCheckbox("noAgent")}
              />{" "}
              No Agent
            </Label>
          </FormGroup>

          {!noAgent &&
            <React.Fragment>
              <FormGroup>
                <Label for="purchaseDate">Select Agent</Label>
                <RealtorSelect onChange={this.onAgentSelect} />
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs="12" sm="3">
                    <Label for="agentName">Agent Name</Label>
                    <Input
                      type="text"
                      name="agentName"
                      id="agentName"
                      value={agentName}
                      onChange={this.onChange}
                    ></Input>
                  </Col>


                  <Col xs="12" sm="3">
                    <Label for="agentEmail">Agent Licence</Label>
                    <Input
                      type="text"
                      name="agentLicence"
                      id="agentLicence"
                      value={agentLicence}
                      onChange={this.onChange}
                    ></Input>
                  </Col>

                  <Col xs="12" sm="3">
                    <Label for="agentEmail">Agent Email</Label>
                    <Input
                      type="text"
                      name="agentEmail"
                      id="agentEmail"
                      value={agentEmail}
                      onChange={this.onChange}
                    ></Input>
                  </Col>

                  <Col xs="12" sm="3">
                    <Label for="agentPhone">Agent Phone</Label>
                    <Input
                      type="text"
                      name="agentPhone"
                      id="agentPhone"
                      value={agentPhone}
                      onChange={this.onChange}
                    ></Input>
                  </Col>
                </Row>
              </FormGroup>
            </React.Fragment>
          }

          <FormGroup>
            <Row>
              <Col xs="12" sm="6">
                <Label for="price">Price</Label>
                <Input
                  type="number"
                  name="price"
                  id="price"
                  value={price}
                  onChange={this.onChange}
                />
              </Col>

              <Col xs="12" sm="6">
                <Label for="financeType">Finance Type</Label>
                <Input
                  type="select"
                  name="financeType"
                  id="financeType"
                  value={financeType}
                  onChange={this.onChange}
                >
                  <option>Select Finance Type</option>
                  <option>FHA</option>
                  <option>Conventional</option>
                  <option>VA</option>
                  <option>USDA</option>
                  <option>Seller Finance</option>
                  <option>Cash</option>
                </Input>
              </Col>

            </Row>
          </FormGroup>

          <FormGroup>
            <Label for="sellerContribution">Seller Contribution</Label>
            <Input
              type="textarea"
              name="sellerContribution"
              id="sellerContribution"
              value={sellerContribution}
              onChange={this.onChange}
            />
          </FormGroup>

        </React.Fragment>
      );
    }
  };

  renderOtherReasonTextBox = () => {
    const { reason, otherReason } = this.state;
    if (reason === "Other") {
      return (
        <FormGroup>
          <Label for="otherReason">Other Reason</Label>
          <Input
            type="textarea"
            name="otherReason"
            id="otherReason"
            value={otherReason}
            onChange={this.onChange}
          ></Input>
        </FormGroup>
      );
    }
  };

  updateShareOnPage = show_on_share => {
    let { showOnSharePage, seller } = this.props;
    showOnSharePage(seller.id, show_on_share);
  }

  renderRemoveSellerModal = data => {

    const { user, seller } = this.props;
    if (!user) return;
    if (user.role === 'lender') return;

    const { modalVisible, reason } = this.state;
    const { seller_name, show_on_share } = data;
    return (
      <React.Fragment>
        {/* <Button
          color="link"
          className="delete"
          style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
          onClick={this.toggle}
        >
          <i style={{ marginRight: 5 }} className="fa fa-remove" />
          Remove
        </Button> */}

        {/* <Button
          color="link"
          title="Profile will show when tab is shared."
          style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
          onClick={() => { this.updateShareOnPage(show_on_share); }}
        >
          <i style={{ marginRight: 5, color: `${(show_on_share) ? 'green' : 'red'}` }} className={`fa fa-${(show_on_share) ? 'check' : 'remove'}`} />
          Show Profile
        </Button> */}

        {/* <Button
          color="link"
          title="Profile will show when tab is shared."
          style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
          onClick={() => { this.updateShareOnPage(show_on_share); }}
        >
          <Link to={`/live-bookings/${sellerId}`} target="_blank">z
            <i style={{ marginRight: 5 }} className="fa fa-eye" />
            Live Bookings ({data.liveBookings || 0})
          </Link>
        </Button> */}
        <br />
        <p className="profile-notify"><small><b>* Profile will show when tab is shared.</b></small></p>

        <Modal
          isOpen={modalVisible}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            Remove {seller_name || "(Seller name)"}
          </ModalHeader>
          <ModalBody style={{ padding: 16 }}>
            <Form>
              <FormGroup>
                <Label for="reasonSelect">Reason</Label>
                <Input
                  type="select"
                  name="reason"
                  id="reasonSelect"
                  onChange={this.onChange}
                  value={reason}
                >
                  <option>Select Reason</option>
                  <option>Not Ready to Sell</option>
                  <option>Property has Sold</option>
                  <option>Other</option>
                </Input>
              </FormGroup>

              {this.renderOtherReasonTextBox()}
              {this.renderSoldFields()}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="danger" onClick={this.remove}>
              Remove
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>
    );
  };

  profileDetails(data) {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    });

    let fields = [
      "HMX_Id",
      "ListingId",
      "address",
      "city",
      "zip_code",
      "property_type",
      // "expected_listing_month",
      // "expected_listing_year",
      // "expected_sales_price",
      "listing_price",
      "subdivision",
      "acres",
      "bedrooms",
      "bathrooms",
      "year_built",
      "garage",
      "school_district",
      "elementary_school",
      "middle_school",
      "high_school",
      "recent_updates",
      "expected_updates",
      "property_description",
      "pool",
      "fireplace",
      "patio",
      "foundation",
      "leaseback_required",
      "leaseback_length",
      "seller_name",
      "seller_email",
      "seller_phone",
      "stories",
      "sqft",
      "living_areas",
      "dining_areas",
      "study",
      "style_of_house",
      // "property_details",
      // "heating_cooling",
      // "age_of_roof",
      // "age_of_hvac",
      // "age_of_condenser_unit",
      // "age_of_water_heater",
      "parking_features",
      "hoa",
      "hoa_dues",
      "hoa_billing_frequency",
      "hoa_includes",
      "common_features"
    ];

    data.bathrooms =
      parseInt(data.full_baths) + parseInt(data.half_baths) * 0.5;

    data.address = !data.hideAddress
      ? this.address(data)
      : "No address specified or address hidden";
    const attrs = fields.filter((f) => {
      if (data.type === 'MLS') {
        return f !== 'HMX_Id'
      }

      return f !== 'ListingId'
    }).map(f => {
      const field = sellerFields[f];

      let value;
      if (f !== "seller_email" && f !== "seller_phone") {
        value = data[f];
      } else {
        if (data.dontDisplayContactInfo) {
          value = "contact hidden";
        } else {
          value = data[f];
        }
      }

      let foundValue;
      if (field && field.options && value) {
        for (const option of field.options) {
          if (typeof option === "object") {
            if (option.key === value) {
              foundValue = option.value;
              break;
            }
          }
        }
      }

      if (!foundValue) {
        if (value) {
          if (typeof value === "string") {
            foundValue = value;
          } else {
            foundValue = value;
          }
        }
      }

      if (field && foundValue) {
        if (field.type === "currency") {
          foundValue = currencyFormatter.format(foundValue);
        }
      }

      let label = field ? field.label || titlecase(f) : titlecase(f);

      return { key: label, value: foundValue || "-" };
    });

    return attrs;
  }
}

export default SellerListItem;
