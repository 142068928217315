import React from "react";
import moment from "moment";

function ScoreEmail(requestInfo, sellerDetails, offerId) {
  return `<!DOCTYPE html>
    <html lang="en" style="font-family: 'Arial', sans-serif; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;">
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Offer Update Notification</title>
        <style>
          body {
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            font-family: 'Arial', sans-serif;
          }
          .container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
          .header {
            background-color: #092267;
            color: #fff;
            text-align: center;
            padding: 20px 10px;
          }
          .header h1 {
            margin: 0;
            font-size: 20px;
          }
          .content {
            padding: 20px;
            color: #333;
          }
          .content p {
            line-height: 1.6;
            margin: 0 0 15px;
          }
          .content a {
            color: #092267;
            text-decoration: none;
            font-weight: bold;
          }
          .offer-summary {
            margin: 20px 0;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 8px;
            background: #f9f9f9;
          }
          .offer-summary p {
            margin: 5px 0;
            font-size: 14px;
          }
          .footer {
            text-align: center;
            padding: 15px 10px;
            background: #f4f4f4;
            font-size: 12px;
            color: #666;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1 style="color: white">Offer Update for ${sellerDetails.street_number} ${sellerDetails.street_name}</h1>
          </div>
          <div class="content">
            <p>Dear ${requestInfo.agentName || "Buyer's Agent"},</p>
            <p>
              We wanted to inform you that an offer has been submitted for ${sellerDetails.street_number} ${sellerDetails.street_name}, 
              which has increased its probability of seller acceptance compared to your client's offer.
            </p>
            <p>If your client would like to improve their standing, you may consider updating their offer. You can do so through the link below:</p>
            <p><a href="https://homematchx.com/makeOffer/${sellerDetails.id}?offer=${offerId}&update=1">Update Offer Details Here</a></p>

            <div class="offer-summary">
              <p><strong>Offer Summary:</strong></p>
              <p><strong>Property:</strong> ${sellerDetails.street_number} ${sellerDetails.street_name}, ${sellerDetails.city}, ${sellerDetails.state}, ${sellerDetails.zip_code}</p>
              <p><strong>Submitted On:</strong> ${moment(requestInfo.createdAt * 1000).format("MMMM Do YYYY, h:mm a")}</p>
              <p><strong>Purchase Price:</strong> $${requestInfo.purchasePrice || "N/A"}</p>
              <p><strong>Finance Type:</strong> ${requestInfo.financingType || "N/A"}</p>
            </div>

            <p>If you have any questions or need assistance, feel free to reach out.</p>

            <p>Best regards,<br>${sellerDetails.seller_name || 'HomematchX'}</p>
          </div>
          <div class="footer">
            <p>Homematchx - Enhancing transparency in real estate offers</p>
          </div>
        </div>
      </body>
    </html>`;
}

export default ScoreEmail;
