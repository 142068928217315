import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = (props) => {
  const { value, total, color, title } = props;

  const normalizedValue = Math.min(value, total);

  const options = {
    legend: {
      display: false
    },
    cutoutPercentage: 85,
    animation: {
      animationRotate: true,
      duration: 2000,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
  };

  const remainder = total - normalizedValue;

  const data = {
    datasets: [
      {
        label: "Score",
        data: [normalizedValue, remainder],
        backgroundColor: [color, "#e9ecef"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div style={{ position: "relative"}}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            textAlign: "center",
            lineHeight: "14px",
            fontSize: "12px",
            fontStyle: "normal",
            margin: 0,
          }}
        >
          {title}
        </p>
        <span
          className="doughnat-score"
        >
          {value}
        </span>
      </div>
      <Doughnut data={data} width={160} height={160} options={options} />
    </div>
  );
};

export default DoughnutChart;